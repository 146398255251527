// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"723671683379be4be1f94d965001357c3c0511bf"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import type { Integration } from '@sentry/types'
import * as Sentry from '@sentry/nextjs'
import ClientConfig from 'lib/config/ClientConfig'

const integrations: Integration[] = [
  Sentry.breadcrumbsIntegration({
    fetch: true,
    history: true,
    xhr: true,
  }),
  Sentry.globalHandlersIntegration({
    onerror: true,
    onunhandledrejection: true,
  }),
]

if (ClientConfig.ENVIRONMENT === 'staging') {
  integrations.push(Sentry.browserTracingIntegration())
  integrations.push(Sentry.captureConsoleIntegration({ levels: ['error', 'warn'] }))
  integrations.push(Sentry.replayIntegration())
  integrations.push(Sentry.feedbackIntegration())
}

const tracesSampleRate = (() => {
  switch (ClientConfig.ENVIRONMENT) {
    case 'staging':
      return 1
    case 'production':
      return 0.5
    default:
      return 0
  }
})()

Sentry.init({
  enabled: ClientConfig.ENVIRONMENT === 'production' || ClientConfig.ENVIRONMENT === 'staging',
  dsn: ClientConfig.SENTRY_CLIENT_DSN,
  environment: ClientConfig.ENVIRONMENT,
  release: ClientConfig.RELEASE,
  maxBreadcrumbs: 50,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Add integrations
  integrations,
})
